export const ROOT = "/";
export const APP_PREFIX_PATH = "/app";
export const PAGES_PREFIX_PATH = "/pages";
export const USERS_PREFIX_PATH = "/patients-info";
export const REFERENCE_DOCTORS_PREFIX_PATH = "/ref-doctors-info";
export const DASHBOARD_PREFIX_PATH = "/dashboard";
export const CONFIGURATION_PREFIX_PATH = "/configurations";
export const PAYMENT_MODES_PREFIX_PATH = "payment-modes";
export const ADD_PATIENT_PREFIX_PATH = "/add-patient";
export const DOCTOR_PREFIX_PATH = "/doctors-info";
export const PATIENT_EDIT_PREFIX_PATH = "/patient-edit";
export const PATIENT_PROFILE_PREFIX_PATH = "/patient-profile";
export const TAGS_PREFIX_PATH = "/tags";
export const BIRTHDAY_PREFIX_PATH = "/birth-day";
export const TREATMENTS_PREFIX_PATH = "/treatments";
export const APPOINTMENTS_PREFIX_PATH = "/appointments";
export const REF_DOCTOR_PROFILE_PREFIX_PATH = "/ref-doctor";
export const EXPENSE_CATEGORIES_PREFIX_PATH = "expense-categories";
export const EXPENSE_PREFIX_PATH = "/expense";
export const ROLES_PREFIX_PATH = "roles";
export const USER_PREFIX_PATH = "/users-info";
export const LOGO_PREFIX_PATH = "logos";
export const COMPANY_NAME_PREFIX_PATH = "company-name";
export const USER_PROFILE_PREFIX_PATH = "/user-profile";
export const FAVICON_PREFIX_PATH = "favicons";
