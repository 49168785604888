import api from "./api/api";

export function getAllFavicon() {
  return api.get("admin/favicons/all");
}

export function createFavicon(data) {
  return api.post("admin/favicon", data);
}

export function deleteFavicon(id) {
  return api.delete(`admin/favicon/${id}`);
}

export function editFavicon(id, data) {
  return api.put(`admin/favicon/${id}`, data);
}
