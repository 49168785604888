import appConfig from "configs/app.config";
import {
  appointmentsPermissionsToCheck,
  dashboardPermissionsToCheck,
  doctorPermissionsToCheck,
  expensePermissionsToCheck,
  patientPermissionsToCheck,
  referenceDoctorPermissionsToCheck,
  tagsPermissionsToCheck,
} from "constants/permissions.constant";
import {
  APPOINTMENTS_PREFIX_PATH,
  DASHBOARD_PREFIX_PATH,
  DOCTOR_PREFIX_PATH,
  EXPENSE_PREFIX_PATH,
  REFERENCE_DOCTORS_PREFIX_PATH,
  TAGS_PREFIX_PATH,
  USERS_PREFIX_PATH,
} from "constants/route.constant";

export function formatDateToDDMMMYYYY(date) {
  const options = { day: "2-digit", month: "short", year: "numeric" };
  return new Date(date).toLocaleDateString("en-GB", options).replace(/ /g, " ");
}

export function extractTimeInIST(isoDateString) {
  const date = new Date(isoDateString);
  const offset = 5.5 * 60;
  const localTime = new Date(
    date.getTime() + (date.getTimezoneOffset() + offset) * 60 * 1000
  );
  let hours = localTime.getHours();
  const minutes = localTime.getMinutes();
  const ampm = hours >= 12 ? "PM" : "AM";
  hours = hours % 12;
  hours = hours ? hours : 12;
  return `${hours.toString().padStart(2, "0")}:${minutes
    .toString()
    .padStart(2, "0")} ${ampm}`;
}

export function extractFileName(url) {
  return url
    .substring(url.lastIndexOf("/") + 1)
    .split(".")
    .slice(1)
    .join(".");
}

export function getPermissionChecks(userData) {
  return {
    dashboard: userData?.role?.assignedPermissions.some((permission) =>
      dashboardPermissionsToCheck.includes(permission?.key)
    ),
    doctor: userData?.role?.assignedPermissions.some((permission) =>
      doctorPermissionsToCheck.includes(permission?.key)
    ),
    patient: userData?.role?.assignedPermissions.some((permission) =>
      patientPermissionsToCheck.includes(permission?.key)
    ),
    refDoctor: userData?.role?.assignedPermissions.some((permission) =>
      referenceDoctorPermissionsToCheck.includes(permission?.key)
    ),
    appointments: userData?.role?.assignedPermissions.some((permission) =>
      appointmentsPermissionsToCheck.includes(permission?.key)
    ),
    expense: userData?.role?.assignedPermissions.some((permission) =>
      expensePermissionsToCheck.includes(permission?.key)
    ),
    tags: userData?.role?.assignedPermissions.some((permission) =>
      tagsPermissionsToCheck.includes(permission?.key)
    ),
  };
}

export function getRedirectRoute(permissionChecks) {
  switch (true) {
    case permissionChecks.dashboard:
      return DASHBOARD_PREFIX_PATH;

    case permissionChecks.doctor:
      return DOCTOR_PREFIX_PATH;

    case permissionChecks.patient:
      return USERS_PREFIX_PATH;

    case permissionChecks.refDoctor:
      return REFERENCE_DOCTORS_PREFIX_PATH;

    case permissionChecks.appointments:
      return APPOINTMENTS_PREFIX_PATH;

    case permissionChecks.expense:
      return EXPENSE_PREFIX_PATH;

    case permissionChecks.tags:
      return TAGS_PREFIX_PATH;

    default:
      return appConfig.authenticatedEntryPath;
  }
}

const predefinedColors = [
  "#FF7F0E",
  "#1F77B4",
  "#BCBD22",
  "#D62728",
  "#9467BD",
  "#17BECF",
  "#7F7F7F",
  "#2CA02C",
  "#E377C2",
  "#8C564B",
  "#FFBB78",
  "#AEC7E8",
  "#C49C94",
  "#F7B6D2",
  "#9EDAE5",
  "#C5B0D5",
  "#98DF8A",
  "#FF9896",
  "#C7C7C7",
  "#FFCF66",
  "#6B6ECF",
  "#B5CF6B",
];

// Helper function to pick random colors from predefined set
export const getRandomColor = () => {
  return predefinedColors[Math.floor(Math.random() * predefinedColors.length)];
};

export const getGreeting = () => {
  const hour = new Date().getHours();
  if (hour < 12) return "Good Morning";
  else if (hour < 17) return "Good Afternoon";
  else return "Good Evening";
};
